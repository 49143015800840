.arrow {
  width: 25px;
  height: 25px;
  background-image: url(../../../images/up-arrow.png);
  background-repeat: no-repeat;
  background-size: contain;
}

.expandIcon {
  width: 22px;
  height: 22px;
  background-size: contain;
  margin-right: 1rem;
  background-color: #3dd1c9;
  border: solid 5px #3dd1c9;
  box-sizing: initial;
  border-radius: 6px;
}

.expandIconOpen {
  background-image: url(../../../images/expand-icon.png);
}

.expandIconClosed {
  background-image: url(../../../images/contract-icon.png);
}

.contentWrapper {
  overflow-y: hidden;
  max-height: 0;
  display: flex;
  flex-direction: column;
}

.topButton {
  border: none;
  padding: 2rem 0;
  background-color: rgba(255, 255, 255, 0);
  text-decoration: none;
  color: #3e3e3e;
  align-items: center;
  justify-content: center;
  display: flex;

  p {
    margin: 0 5px 0 0;
    line-height: 1.2rem;
  }
}

.topButton:hover {
  color: #3e3e3e;
}

.title {
  cursor: pointer;
  margin-bottom: 0.5rem;
  background-color: #e1e1e1;
  padding: 0.6rem 1rem;
  display: flex;
  align-items: center;
  border-radius: 5px;
  height: 6rem;

  h2 {
    pointer-events: none;
    margin: 4px 0 0;
    font-size: 1.3rem;
    line-height: 1.6rem;
    flex: 1;

    // bold weight of light version
    font-family: "Proxima-Nova-Alt-Light";
    font-weight: bold;
  }
}

.open {
  max-height: unset;
  margin-bottom: 2rem;
}

.openAnim {
  animation-name: open;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.475, 0.275, 0.265, 0.865);
}

.closeAnim {
  animation-name: close;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.065, 0.975, 0.09, 0.98);
}

@media screen and (orientation: landscape) {
  @keyframes open {
    0% {
      max-height: 0;
      margin-top: 0;
    }

    100% {
      max-height: 1500px;
      margin-top: 2rem;
    }
  }

  @keyframes close {
    0% {
      max-height: 1500px;
      margin-top: 2rem;
    }

    100% {
      max-height: 0;
      margin-top: 0;
    }
  }
}

@media screen and (orientation: portrait) {
  @keyframes open {
    0% {
      max-height: 0;
      margin-top: 0;
    }

    100% {
      max-height: 3000px;
      margin-top: 2rem;
    }
  }

  @keyframes close {
    0% {
      max-height: 3000px;
      margin-top: 2rem;
    }

    100% {
      max-height: 0;
      margin-top: 0;
    }
  }
}
