.wrapper {
  input {
    width: 100%;
    height: 2.5rem;
    padding: 0 0.7rem;
    box-sizing: border-box;
    font-size: 1rem;
    color: grey;
    border-radius: 5px;
    border: solid 1px #b6b7b9;
  }
}
