.wrapper {
  width: 100%;
  height: 25%;
  background-color: dimgrey;
  position: absolute;
  top: 0;

  // no longer used
  // display: flex;
  display: none;

  color: white;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  pointer-events: none;
  font-size: 1.2rem;
  line-height: 1.3rem;
  text-align: center;

  p {
    margin-bottom: 0;
  }
}
