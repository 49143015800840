@import "../mixins";

.page {
  flex: 1;
  position: relative;
}

.loginButton {
  width: unset;
  height: unset;
  min-width: unset;
}

.bgImage {
  background-image: url(../../images/head-shot-small.png);
  background-repeat: no-repeat;
  background-size: contain;
}

// gradient with flat fallback
.bgGradient {
  background-color: $page-home-bg-color;
  background-image: $page-home-bg-gradient;
}

.malcName {
  color: white;
}

.title {
  color: darkgrey;
  letter-spacing: max(-0.1rem, -1px);
}

.about {
  color: white;
  margin-bottom: 0;
}

@media screen and (orientation: landscape) {
  .malcName {
    margin-top: 0;
    margin-bottom: min(6vw, 71px);
  }

  .bgImage {
    background-position: bottom 0% right -10vw;
  }

  .loginButton {
    font-size: min(2.5vw, 29px);
    line-height: min(2.5vw, 29px);
    padding: min(1.5vw, 17px) min(5vw, 59px);
    margin-top: min(3vw, 35px);
  }

  .title {
    margin-top: max(-5.5vw, -65px);
    font-size: min(4vw, 47px);
    line-height: min(4vw, 47px);
    margin-bottom: 0;
  }

  .about {
    width: 50%;
    font-size: min(2.6vw, 31px);
    line-height: min(3vw, 35px);
    margin-top: min(2vw, 23px);
  }
}

@media screen and (orientation: portrait) {
  .malcName {
    margin-top: 0;
    margin-bottom: min(10vw, 77px);
  }

  .bgImage {
    background-position: bottom 0% right -20vw;
  }

  .loginButton {
    font-size: min(4.5vw, 34px);
    line-height: min(4.5vw, 34px);
    padding: min(4vw, 16px) min(10vw, 41px);
    margin-top: min(6vw, 2.4rem);
  }

  .title {
    margin-top: max(-9vw, -68px);
    margin-bottom: 0;
    font-size: min(7vw, 53px);
    line-height: min(7vw, 53px);
  }

  .about {
    width: 80%;
    font-size: min(5vw, 38px);
    line-height: min(5.5vw, 41px);
    margin-top: min(4vw, 30px);
  }
}

@media screen and (min-width: 1px) {
}
@media screen and (min-width: 576px) {
}
@media screen and (min-width: 768px) {
}
@media screen and (min-width: 992px) {
}
