@import '../../mixins';

.button {
  background-color: unset;
  padding: $nav-open-button-padding;
  border: none;
  align-self: flex-start;
}

.menuIcon {
  height: $nav-open-button-icon-height;
}