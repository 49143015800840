.wrapper {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: -25%;
  height: 25%;
  display: flex;
}

.option {
  flex: 1;
  background: unset;
  padding: 0;
  text-decoration: none;
  color: white;
  font-family: "Proxima-Nova-Bold";
  border: solid 3px #3dd1c9;
}

.option:last-child {
  border-left: none;
}

.option:hover {
  background: black;
  color: white;
}

.icon {
  width: 100%;
  height: 100%;
  background-size: auto 60%;
  background-repeat: no-repeat;
  background-position: center;
}

.image {
  background-image: url(../../../images/icon-magnify.png);
}

.info {
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageOption {
  flex: initial;
  width: 60px;
}
