.wrapper {
  width: 100%;
  max-width: 500px;
  height: 1.8rem;
  background-color: lightgrey;
  display: flex;
  margin-bottom: 0.5rem;
}

.label {
  width: 7rem;
  height: 100%;
  background-color: #3dd1c9;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.valueBarContainer {
  height: 100%;
  flex: 1;
  position: relative;
}

.bar {
  width: 0%;
  height: 100%;
  background-color: lightseagreen;
}

.value {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: 0;
  padding-right: 1rem;
}
