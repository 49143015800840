.item {
  width: 100%;
}

.title {
  font-weight: bold;
  font-size: 1.4rem;
  margin: 1rem 0 0.5rem;
  background-color: #3dd1c9;
  padding: 0.5rem 1rem;
  color: white;
  border-radius: 6px;
}

@media screen and (min-width: 768px) {
  .title {
    margin-top: 2rem;
  }
}
