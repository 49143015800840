.spinnerWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  flex-direction: column;
  justify-content: center;
}

.spinnerBand {
  height: calc(100% - (0.6rem * 2));
  background-color: white;
  opacity: 0.6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 5rem;
}

.show {
  display: flex;
}
