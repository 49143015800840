.wrapper {
  min-width: 7rem;
  border: none;
  color: white;
  font-size: 1.5rem;
  border-radius: 5px;
  text-decoration: none;
  text-align: center;
  display: block;
}

.wrapper:hover {
  color: white;
}
