.item {
  display: flex;
  height: 6rem;
  align-items: center;

  img {
    width: 50px;
    height: 50px;
    margin-right: 1rem;
    background-color: #3dd1c9;
    border: solid 8px #3dd1c9;
    box-sizing: initial;
    border-radius: 8px;
  }

  p {
    margin-bottom: 0;
  }
}
