.wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  
  p {
    word-wrap: break-word;
    padding-right: 1rem;
    box-sizing: border-box;
  }

  input {
    height: 2.5rem;
    align-self: center;
    padding: 0 0.7rem;
    box-sizing: border-box;
    font-size: 1rem;
    color: grey;
    border-radius: 5px;
    border: solid 1px #b6b7b9;
  }
}

@media screen and (min-width: 1px) {
  .wrapper {
    flex-direction: column;

    p {
      margin-bottom: 0.3rem;
      font-size: 1.2rem;
      line-height: 1.3rem;
    }

    input {
      width: 100%;
    }
  }
}

@media screen and (min-width: 576px) {
  .wrapper {
    flex-direction: row;
    align-items: center;

    p {
      width: 35%;
      margin-bottom: 0;
      font-size: 1.5rem;
      line-height: 1.5rem;
    }

    input {
      width: 65%;
    }
  }
}