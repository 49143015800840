// general navigation
$nav-background-color-narrow: rgba(0, 0, 0, 0.5);
$nav-background-color-narrow-open: rgba(0, 0, 0, 0.9);
$nav-background-color-wide: rgba(0, 0, 0, 0.85);
$nav-box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.25);
$nav-height: 5rem;
$nav-logo-height-perc-narrow: 60%;
$nav-logo-height-perc-wide: 55%;
$nav-logo-side-margin-narrow: 0.8rem;
$nav-logo-side-margin-wide: 2.0rem;
$nav-open-button-padding: 1.38rem;
$nav-open-button-icon-height: 2rem;
$nav-text-size: 1.2rem;

// main navigation
$nav-main-min-width: 7rem;
$nav-main-padding-narrow: 1.8rem;
$nav-main-padding-mid: 2.3rem 0.5rem;
$nav-main-padding-wide: 2.3rem;
$nav-main-text-color: white;

// sub navigation
$nav-sub-background-color-narrow: black;
$nav-sub-background-color-wide: rgba(0, 0, 0, 0.9);
$nav-sub-min-width: 12rem;
$nav-sub-margin: 0.5rem;
$nav-sub-padding-narrow: 1.2rem;
$nav-sub-padding-wide: 1.0rem;
$nav-sub-text-color-narrow: #18ebe0;
$nav-sub-text-color-wide: #18ebe0;

// page
$page-bg-color-1: white;
$page-bg-color-2: #f3f0f0;
$page-max-width: 1200px;
$page-padding-top-v-narrow: 2rem;
$page-padding-bottom-v-narrow: 6rem;
$page-padding-h-narrow: 2rem;
$page-padding-top-v-wide: 2rem;
$page-padding-bottom-v-wide: 6rem;
$page-padding-h-wide: 3rem;

// page tint
$page-tint-background-color-narrow: rgba(0, 0, 0, 0.6);
$page-tint-background-color-wide: rgba(0, 0, 0, 0.2);

// home page
// color 1 has a gradient with flat fallback
$page-home-bg-color: #6c6c6c;
$page-home-bg-gradient: linear-gradient(
  10deg,
  rgba(41, 41, 41, 1) 0%,
  rgba(68, 68, 68, 1) 15%,
  rgba(108, 108, 108, 1) 100%
);
