.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto 6rem;
}

.logo {
  position: relative;
  padding: 5px;
  width: 69px;
  height: 69px;

  img {
    width: 100%;
    height: 100%;
  }

  p {
    position: absolute;
    top: 0;
  }
}
