@import "../mixins";

.roundedImage {
  img {
    border-radius: 20px;
  }
}

.textToComplete {
  animation-name: flashText;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.clear {
  clear: both;
}

.codeText {
  color: #1ca9a1;
  font-family: "Courier New", Courier, monospace;
  font-size: 1.1rem;
}

.contentSubBlock {
  margin-bottom: 3rem;
}

.clear {
  clear: both;
}

.contentImageWrapper {
  margin-bottom: 2.5rem;
}

.contentImage {
  width: 100%;
}

.contentBlock {
  margin-bottom: 6rem;

  h2 {
    font-size: 2.5rem;
    line-height: 2.5rem;
    margin-bottom: 2rem;
    margin-top: 0;
  }

  h3 {
    font-size: 1.8rem;
    line-height: 1.8rem;
    margin-bottom: 1rem;
    color: #9f9f9f;
  }
}

.outerWrapper {
  display: flex;
  justify-content: center;
  box-shadow: inset 0px 6px 5px -4px rgba(0, 0, 0, 0.1);
  border-top: solid 1px #ddd;
  overflow: hidden;
}

.outerWrapperFirst {
  padding-top: $nav-height;
  box-shadow: none;
  border-top: 0;
}

.wrapper {
  width: 100%;
  max-width: $page-max-width;
}

.innerWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.color1 {
  background-color: $page-bg-color-1;
}

.color2 {
  background-color: $page-bg-color-2;
}

.lightboxWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, 320px);
  grid-gap: 20px;
  justify-content: center;
  align-items: start;
  margin-top: 40px;

  & > div {
    position: relative;
    box-shadow: 2px 2px 8px 4px rgb(0 0 0 /30%);
  }

  & img {
    width: 100%;
  }

  & a {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
  }
}

@media screen and (min-width: 1px) {
  .wrapper {
    padding: $page-padding-top-v-narrow $page-padding-h-narrow $page-padding-bottom-v-narrow;
  }

  .innerWrapperFirst {
    min-height: calc(100vh - ($nav-height + $page-padding-top-v-narrow + $page-padding-bottom-v-narrow));
  }

  .contentBlock {
    display: flex;
    flex-direction: column;

    h3 {
      margin-top: 1rem;
    }
  }

  .contentSubBlock {
    display: flex;
    flex-direction: column;
  }

  .contentImageWrapper {
    max-width: 25rem;
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
    order: 1;
  }
}

@media screen and (min-width: 576px) {
  .wrapper {
    padding: $page-padding-top-v-wide $page-padding-h-wide $page-padding-bottom-v-wide;
  }

  .innerWrapperFirst {
    min-height: calc(100vh - ($nav-height + $page-padding-top-v-wide + $page-padding-bottom-v-wide));
  }
}

@media screen and (min-width: 768px) {
  .contentBlock {
    display: block;

    h3 {
      margin-top: 3rem;
    }
  }

  .contentSubBlock {
    display: block;
  }

  .contentImageWrapper {
    max-width: 20rem;
    margin-top: 0;
  }

  .leftImage {
    float: left;
    margin-right: 50px;
  }

  .rightImage {
    float: right;
    margin-left: 50px;
  }

  .lightboxWrapper {
    justify-content: flex-start;
  }
}

@media screen and (min-width: 992px) {
}

@media screen and (min-width: 1200px) {
}

@keyframes flashText {
  0% {
    background-color: rgba(255, 255, 0, 0);
  }

  25% {
    background-color: rgba(255, 255, 0, 0.3);
  }

  75% {
    background-color: rgba(255, 255, 0, 0.3);
  }

  100% {
    background-color: rgba(255, 255, 0, 0);
  }
}
