.wrapper {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;

  p {
    text-align: center;
    margin-bottom: 0;
  }
}

.showWrapper {
  display: flex;
}

.fadeInWrapper {
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  animation-duration: 0.5s;
}

.fadeOutWrapper {
  animation-name: fadeOut;
  animation-fill-mode: forwards;
  animation-duration: 0.5s;
}

.box {
  width: 200px;
  height: 200px;
  background-color: white;
  border-radius: 0.6rem;
  box-shadow: 0px 0px 15px 10px rgb(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }  
}