.wrapper {
  position: absolute;
  bottom: 0;
  margin-left: 10vw;
}

.arrow {
  background-image: url(../../../images/arrow.png);
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0;
}

@media screen and (orientation: landscape) {
  .arrow {
    width: min(6vw, 71px);
    height: min(3.7vw, 43px);
  }
}

@media screen and (orientation: portrait) {
  .arrow {
    width: min(13vw, 99px);
    height: min(8vw, 60px);
  }
}
