.wrapper {
  width: 100%;
  height: 3.3rem;
  position: absolute;
  top: 0.9rem;
  left: 0;
  background-color: #dd5c5c;
  color: white;
  display: none;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 15px;

  p {
    margin-bottom: 0;
  }
}

.wrapperShow {
  display: flex;
}
