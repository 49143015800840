.tel {
  display: inline-block;
  font-size: 2.2rem;
  text-decoration: none;

  p {
    color: #3e3e3e;
  }
}

.title {
  color: #3dd1c9;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 1rem;
}

@media screen and (min-width: 1px) {
  .title {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 576px) {
  .title {
    font-size: 2.5rem;
  }
}
