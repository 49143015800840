@import "../../mixins";

.wrapper {
  a {
    text-decoration: none;
  }

  button {
    border: none;
    padding: 0;
    display: contents;
  }
}

.narrowWrapper {
  position: relative;
}

.link {
  color: $nav-main-text-color;
  margin: 0;
  text-align: center;
  white-space: nowrap;
  min-width: $nav-main-min-width;
  font-size: $nav-text-size;
  line-height: 0;
}

.logout {
  color: #ff5454;
  font-weight: bold;
}

.disabledLink {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}

.navList {
  box-shadow: $nav-box-shadow;
  position: absolute;
  z-index: 11;
}

.sub {
  margin: $nav-sub-margin;
  min-width: $nav-sub-min-width;
}

@media screen and (min-width: 1px) {
  .wrapper {
    width: 100%;
  }

  .navList {
    width: 100%;
    background-color: $nav-sub-background-color-narrow;
  }

  .link {
    padding: $nav-main-padding-narrow;
  }

  .sub {
    padding: $nav-sub-padding-narrow;
    color: $nav-sub-text-color-narrow;
  }
}
@media screen and (min-width: 576px) {
}
@media screen and (min-width: 768px) {
  .wrapper {
    width: unset;
  }

  .navList {
    background-color: $nav-sub-background-color-wide;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: calc(100vh - 5rem);
    align-content: flex-start;
    width: 100%;
    left: 0;
  }

  .link {
    padding: $nav-main-padding-mid;
  }

  .sub {
    padding: $nav-sub-padding-wide;
    color: $nav-sub-text-color-wide;
    text-align: left;
  }
}
@media screen and (min-width: 992px) {
  .link {
    padding: $nav-main-padding-wide;
  }

  .sub {
    padding: $nav-sub-padding-wide;
  }
}
@media screen and (min-width: 1200px) {
}
