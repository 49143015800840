.wrapper {
  position: relative;
  opacity: 0;
}

.left {
  left: -600px;
}

.right {
  right: -600px;
}
