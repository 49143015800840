.textInputSlideOn {
  margin-bottom: 2rem;
}

.textAreaSlideOn {
  height: 100%;
}

.title {
  color: #3dd1c9;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 1rem;
}

.outerWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.wrapper {
  display: flex;
  width: 100%;
  margin-bottom: 1.5rem;
}

.submitButton {
  padding: 0.3rem 1rem;
}

.detailsBlock {
  flex: 1;
}

.messageBlock {
  flex: 1;
}

@media screen and (min-width: 1px) {
  .wrapper {
    flex-direction: column;
  }

  .detailsBlock {
    margin-right: 0;
  }

  .messageBlock {
    margin-left: 0;
  }

  .title {
    font-size: 1.8rem;
  }

  .submitButton {
    width: 100%;
  }
}

@media screen and (min-width: 576px) {
  .wrapper {
    flex-direction: row;
  }

  .textInputSlideOn:last-child {
    margin-bottom: 0;
  }

  .detailsBlock {
    margin-right: 1.5rem;
  }

  .messageBlock {
    margin-left: 1.5rem;
  }

  .title {
    font-size: 2.5rem;
  }

  .submitButton {
    width: unset;
  }
}
