.wrapper {
  height: 100%;
  margin-bottom: 0;

  textarea {
    width: 100%;
    height: 100%;
    padding: 1rem 1.2rem;
    box-sizing: border-box;
    font-size: 1rem;
    color: grey;
    border-radius: 5px;
    border: solid 1px #b6b7b9;
  }
}

@media screen and (min-width: 1px) {
  .wrapper {
    height: 15rem;
  }
}

@media screen and (min-width: 576px) {
  .wrapper {
    height: 100%;
  }
}
