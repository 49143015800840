.emailLink {
  text-decoration: none;
  color: #20b2aa;
}

.logo {
  width: 70%;
  filter: invert(100%);
  opacity: 0.25;
}

.logoWrapper {
  text-align: right;
}

.titleWrapper {
  display: flex;

  h2 {
    margin: 0 0 1.5rem;
    font-size: 2rem;
    flex: 1;
  }
}

.wrapper {
  background-color: white;
  padding: 15px;
  font-size: 1.1rem;
  box-sizing: border-box;
  border-radius: 0.6rem;
  box-shadow: 0px 0px 15px 10px rgb(0, 0, 0, 0.2);
  position: relative;
}

.emailInput {
  text-transform: lowercase;
}

.buttons {
  display: flex;
}

.outerWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 1px) {
  .wrapper {
    width: 280px;
  }

  .wide {
    display: none;
  }

  .narrow {
    display: block;
  }

  .buttons {
    justify-content: space-evenly;
    margin-top: 1.5rem;
  }
}

@media screen and (min-width: 576px) {
  .wrapper {
    width: 90%;
    max-width: 450px;
  }

  .buttons {
    justify-content: flex-end;
    margin-top: 2rem;
  }

  .okButton {
    margin-left: 1rem;
  }
}

@media screen and (min-width: 768px) {
  .wide {
    display: block;
  }

  .narrow {
    display: none;
  }
}

@media screen and (min-width: 992px) {
}
@media screen and (min-width: 1200px) {
}
