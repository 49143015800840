.paper {
  background-color: #f3f3f3;
  box-shadow: 1px 2px 5px 2px rgba(0, 0, 0, 0.2);
  margin: 0 auto 1rem;
  max-width: 800px;
  position: relative;
  font-size: 1.3rem;
  transform: rotate(2deg);

  div {
    padding: 1.5rem;
  }
}

.title {
  background-color: rgba(61, 209, 201, 1);
  color: white;
  border-bottom: solid 2px white;
  font-family: "Proxima-Nova-Bold";
  width: 100%;
  padding: 1rem 1.5rem;
  font-size: 1.4rem;
  text-align: center;
  margin-bottom: 0;
}

.quotes {
  width: 15%;
  height: calc(100% - 3rem);
  background-image: url(../../../images/quotes.png);
  position: absolute;
  background-size: contain;
  bottom: 30px;
  right: 30px;
  background-repeat: no-repeat;
  opacity: 0.25;
  background-position-y: bottom;
}

.content {
  position: relative;
}

.name {
  font-size: 1rem;
  font-style: italic;
}