@import "../../mixins";

.logoutButtonOuterWrapper {
  width: 100%;
  position: absolute;
  top: 100%;
  right: 0px;
  z-index: -1;
  display: flex;
  justify-content: center;
}

.logoutButtonWrapper {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: flex-end;
}

.logoutButton {
  border-radius: 0 !important;
  height: unset !important;
  width: 120px !important;
  line-height: 2rem;
}

.logoutButtonLoggedIn {
  animation-name: logoutButtonAnimIn;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.logo {
  opacity: 0.8;
}

.subCategory {
  background-color: #32504f;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  margin: 0;
  color: black;
  font-weight: bold;
  text-align: center;
}

.stickyWrapper {
  position: relative;
  z-index: 10;
  display: none;
}

.stickyWrapperShow {
  display: block;
}

.outerWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.narrowUI {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.logoWrapper {
  display: flex;
  align-items: center;
  align-self: normal;
}

.narrowNavOpen {
  height: calc(100vh - $nav-height);
  overflow-y: scroll;
}

.wrapper {
  position: relative;
  top: calc($nav-height * -1);
}

.wrapperLoggedIn {
  animation-name: loggedInAnim;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

.wrapperLoggedOut {
  animation-name: loggedOutAnim;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
}

.bottomSpacer {
  height: 100px;
}

@media screen and (min-width: 1px) {
  .wrapper {
    flex-direction: column;
    background-color: $nav-background-color-narrow;
    box-shadow: none;
  }

  .open {
    background-color: $nav-background-color-narrow-open;
  }

  .closed {
    flex-direction: row;
  }

  .wide {
    display: none;
  }

  .narrow {
    position: relative;
  }

  .logo {
    width: $nav-logo-height-perc-narrow;
    margin-left: $nav-logo-side-margin-narrow;
    margin-right: 0;
  }

  .logoWrapper {
    justify-content: flex-start;
  }
}
@media screen and (min-width: 576px) {
}
@media screen and (min-width: 768px) {
  .wrapper {
    background-color: $nav-background-color-wide;
    box-shadow: $nav-box-shadow;
  }

  .mainNav {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    // reset possible scrolling of the narrow nav
    height: auto;
    overflow-y: unset;
  }

  .wide {
    display: flex;
    align-items: center;
  }

  .narrow {
    display: none;
  }

  .logo {
    width: $nav-logo-height-perc-wide;
    margin-left: 0;
    margin-right: $nav-logo-side-margin-wide;
  }

  .logoWrapper {
    justify-content: flex-end;
  }

  .innerWrapper {
    width: 100%;
    max-width: $page-max-width;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }

  .subCategory {
    text-align: left;

    // See comments about writing-mode in nav-element.module.scss:navList.
    writing-mode: initial;
  }

  .bottomSpacer {
    height: 0;
  }
}
@media screen and (min-width: 992px) {
}
@media screen and (min-width: 1200px) {
}

@keyframes loggedInAnim {
  0% {
    top: calc($nav-height * -1);
  }

  50% {
    top: calc($nav-height * -1);
  }

  100% {
    top: 0;
  }
}

@keyframes loggedOutAnim {
  0% {
    top: 0;
  }

  100% {
    top: calc($nav-height * -1);
  }
}

@keyframes logoutButtonAnimIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes logoutButtonAnimOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
