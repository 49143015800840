.wrapper {
  width: 100%;
}

.screenHeightMeasurer {
  width: 0;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}
