@import '../../mixins';

.outerWrapper {
  display: flex;
}

.borderWrapper {
  width: 100%;
  position: relative;
}

.banner {
  width: 122px;
  height: 119px;
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  top: -1px;
  z-index: 1;
  pointer-events: none;
}

.bannerReplica {
  background-image: url(../../../images/showcase-banner-replica.png);
}

.bannerPrototype {
  background-image: url(../../../images/showcase-banner-prototype.png);
}

.bannerGame {
  background-image: url(../../../images/showcase-banner-game.png);
}

.bannerDesign {
  background-image: url(../../../images/showcase-banner-design.png);
}

.leftSpacer {
  width: 1rem;
}

.rightSpacer {
  width: 1rem;
}

.wrapper {
  width: 100%;
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  overflow: hidden;
  border: solid 10px #ababab;
}

.clickableArea {
  width: 100%;
  height: 100%;
  // no longer tint
  // background-color: rgba(255, 255, 255, 0.3);
}

@media screen and (min-width: 1px) {
  .wrapper {
    margin-bottom: 1rem;
  }

  .leftSpacer {
    display: none;
  }

  .rightSpacer {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .wrapper {
    margin-bottom: 2rem;
  }

  .leftSpacer {
    display: block;
  }

  .rightSpacer {
    display: block;
  }
}

@media screen and (min-width: 992px) {
  .borderWrapper {
    border: solid 0.4rem $page-bg-color-2;
  }

  .wrapper {
    margin: 0;
  }

  .leftSpacer {
    display: none;
  }

  .rightSpacer {
    display: none;
  }
}

.pointerTitleAnimateIn {
  animation: titleMove 1s;
  animation-fill-mode: forwards;
}

.pointerOptionsAnimateIn {
  animation: optionsMove 0.5s;
  animation-fill-mode: forwards;
}

.clickableAreaHover {
  animation: bgTint 0.5s;
  animation-fill-mode: forwards;
}

.wrapperHover {
  // no longer change border
  // border-color: black !important;
}

.disable {
  pointer-events: none;
}

.touchTitleAnimateIn {
  animation: titleMove 1s;
  animation-fill-mode: forwards;
}

.touchOptionsAnimateIn {
  animation: optionsMove 0.5s;
  animation-fill-mode: forwards;
}

.clickableAreaTouch {
  animation: bgTint 0.5s;
  animation-fill-mode: forwards;
}

.wrapperTouch {
  // no longer change border
  // border-color: black !important;
}

@keyframes titleMove {
  0% {
    top: 0%;
  }

  25% {
    top: 0%;
  }

  100% {
    top: -25%;
  }
}

@keyframes optionsMove {
  0% {
    bottom: -25%;
  }

  100% {
    bottom: 0%;
  }
}

@keyframes bgTint {
  0% {
    // no longer tint
    // background-color: rgba(255, 255, 255, 0.2);
  }

  100% {
    // no longer tint
    // background-color: rgba(255, 255, 255, 0);
  }
}
