body {
  font-family: "Proxima-Nova-Alt-Light";
  color: #3e3e3e;
  font-size: 1.2rem;
  overflow-x: hidden;
}

button {
  font-family: "Proxima-Nova-Alt-Light";
}

h1 {
  font-family: "Proxima-Nova-Bold";
}

h2,
h3,
h4,
h5 {
  font-family: "Proxima-Nova-Alt-Light";
}

h2 {
  margin-top: 3rem;
  margin-bottom: 2rem;
}

p {
  margin: 0;
  line-height: 1.6rem;
  margin-bottom: 1rem;
}

//-------------------------------------------
// override react-image-lightbox
//-------------------------------------------

.ril__outer {
  // background page tint
  background-color: rgba(0, 0, 0, 0.65);
}

.ril__toolbar {
  background-color: unset;
}

.ril__builtinButton {
  opacity: 1;
  background-color: indianred;
  border: solid 2px white;
}

.ril__builtinButton:hover {
  background-color: red;
}

.ril__zoomInButton {
  display: none;
}

.ril__zoomOutButton {
  display: none;
}

//-------------------------------------------
// end override react-image-lightbox
//-------------------------------------------

//-------------------------------------------
// override bootstrap
//-------------------------------------------

@media (min-width: 1px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 370px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: unset;
  }
}

@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: unset;
  }
}

.row > * {
  padding-right: unset;
  padding-left: unset;
}

//-------------------------------------------
// end override bootstrap
//-------------------------------------------

//-------------------------------------------
// override react-highlight
//-------------------------------------------

// Fix for iPhone 6s which previously wrapped the code.
.hljs-tag {
  white-space: nowrap;
}

//-------------------------------------------
// end override react-highlight
//-------------------------------------------

//-------------------------------------------
// media queries
//-------------------------------------------

@media screen and (orientation: landscape) {
  h1 {
    font-size: min(8vw, 95px);
    letter-spacing: max(-0.4vw, -4px);
    line-height: min(6vw, 71px);
    margin-bottom: min(10vw, 119px);
    margin-top: min(4vw, 47px);
  }
}

@media screen and (orientation: portrait) {
  h1 {
    font-size: min(13vw, 99px);
    letter-spacing: max(-0.7vw, -5px);
    line-height: min(9.5vw, 72px);
    margin-bottom: min(10vw, 76px);
    margin-top: min(4vw, 29px);
  }
}

//-------------------------------------------
// end media queries
//-------------------------------------------

//-------------------------------------------
// fonts
//-------------------------------------------

@font-face {
  font-family: "Proxima-Nova-Alt-Light";
  src: url("../fonts/proxima-nova-alt-light/Proxima-Nova-Alt-Light.woff") format("woff"),
    url("../fonts/proxima-nova-alt-light/Proxima-Nova-Alt-Light.woff2") format("woff2"),
    url("../fonts/proxima-nova-alt-light/Proxima-Nova-Alt-Light.eot"),
    url("../fonts/proxima-nova-alt-light/Proxima-Nova-Alt-Light.eot?#iefix") format("embedded-opentype"),
    url("../fonts/proxima-nova-alt-light/Proxima-Nova-Alt-Light.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima-Nova-Bold";
  src: url("../fonts/proxima-nova-bold/Proxima-Nova-Bold.woff") format("woff"),
    url("../fonts/proxima-nova-bold/Proxima-Nova-Bold.woff2") format("woff2"),
    url("../fonts/proxima-nova-bold/Proxima-Nova-Bold.eot"),
    url("../fonts/proxima-nova-bold/Proxima-Nova-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/proxima-nova-bold/Proxima-Nova-Bold.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima-Nova-Alt-Light-Italic";
  src: url("../fonts/proxima-nova-alt-light-italic/Proxima-Nova-Alt-Light-Italic.woff") format("woff"),
    url("../fonts/proxima-nova-alt-light-italic/Proxima-Nova-Alt-Light-Italic.woff2") format("woff2"),
    url("../fonts/proxima-nova-alt-light-italic/Proxima-Nova-Alt-Light-Italic.eot"),
    url("../fonts/proxima-nova-alt-light-italic/Proxima-Nova-Alt-Light-Italic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/proxima-nova-alt-light-italic/Proxima-Nova-Alt-Light-Italic.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

//-------------------------------------------
// end fonts
//-------------------------------------------
