@import '../../mixins';

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.wide {
  background-color: $page-tint-background-color-wide;
}

.narrow {
  background-color: $page-tint-background-color-narrow;
}
